import React, { Component } from 'react';
import { Form, Input, Button, message, Modal } from 'antd';
import { connect } from 'react-redux';
import { changeRoleLogin } from '../../redux/actions/Login';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import axios from 'axios';
import './Login.less';
import baseConfig from '../../config/BaseConfig';
import { CompanyData, DomainData } from '../../config/Common';

/* 登录 */
// UI组件
class Login extends Component {

    state = {
        exitVisible: false,
        exitBtnVisible: false,
    }

    componentDidMount() {

        var userAgent = navigator.userAgent.toLowerCase();

        if (userAgent.indexOf(' electron/') > -1) {

            // 在 Electron 中运行
            this.setState({ exitBtnVisible: true })

        } else {

            // 在浏览器中运行

        }
    }

    // 登录验证成功
    onFinish = (values) => {
        axios.post(baseConfig.httpUrl + '/api/user/login', { uname: values.username, pass: values.password }).then(
            response => {
                if (response.data.res === 1) {
                    this.props.changeRole({
                        role: response.data.role,
                        full_name: response.data.full_name,
                        icon: response.data.icon,
                        uname: response.data.uname,
                        has_agree: response.data.has_agree,
                        user_id: response.data.user_id ? response.data.user_id : '',
                        group_name: response.data.group_name ? response.data.group_name : '',
                        display_kanban: response.data.display_kanban ? response.data.display_kanban : '',
                        birthday: response.data.birthday ? response.data.birthday : '',
                        gender: response.data.gender ? response.data.gender : '',
                    });

                    if (response.data.has_agree == 0) {
                        this.props.history.push(baseConfig.routeUrl + '/agreement');
                    }
                    else {
                        if (response.data.role == 2) {
                            this.checkActive()
                        }
                        else {
                            this.props.history.replace(baseConfig.routeUrl);
                        }
                    }
                    // this.props.history.replace(baseConfig.routeUrl);

                }
                else {
                    message.error(response.data.msg);
                }
            }
        )
    };

    /**
     * 检查是否需要激活
     * @param {*} data 
     */
    checkActive = () => {
        axios.get(baseConfig.httpUrl + '/api/user/is-activated', {}).then(
            response => {
                if (response.data.res === 1) {
                    if (response.data.is_activated) {
                        this.props.history.replace(baseConfig.routeUrl);
                    }
                    else {
                        this.props.history.replace(baseConfig.routeUrl + '/active');
                    }
                }
                else {
                    message.error(response.data.msg);
                }
            }
        )
    }

    // 忘记密码
    forgetPwd = () => {
        this.props.history.push(baseConfig.routeUrl + '/forgetpwd');
    }

    /**
     * 退出
     */
    exitFun = () => {
        this.setState({ exitVisible: true })
    }

    /**
     * 退出 确认
     */
    exitComfirm = () => {
        window.close();
    }

    /**
     * 退出 取消
     */
    exitCancel = () => {
        this.setState({ exitVisible: false })
    }

    /**
     * 隐私政策
     */
    privacyFun = () => {
        window.open(baseConfig.routeUrl + '/privacy', '_blank')
    }

    /**
     * 用户协议
     */
    serverFun = () => {
        window.open(baseConfig.routeUrl + '/service', '_blank')
    }

    render() {
        let loginName = '脑云梯脑智综合评估系统';
        let domainUrl = window.location.host;
        let companyInfoDisplay = 'block';
        let fontSizeVal = '24px';

        if ('assess.mind360.com.cn' == domainUrl) {
            companyInfoDisplay = 'none'
        }

        if ('cp.pkuzk.cn' == domainUrl) {
            fontSizeVal = '18px'
        }

        for (let key in DomainData) {
            if (domainUrl == key) {
                loginName = DomainData[key];
            }
        }

        let companyName = '北京视友科技有限责任公司';

        for (let key in CompanyData) {
            if (domainUrl == key) {
                companyName = CompanyData[key];
            }
        }

        const { exitVisible, exitBtnVisible } = this.state

        return (
            <div className='login'>

                < Modal
                    className='exitModal'
                    title='提示'
                    visible={exitVisible}
                    onOk={this.exitComfirm}
                    onCancel={this.exitCancel}
                    maskClosable={false}
                >
                    <h3 className='exitContent'>确定要关闭系统吗</h3>
                </Modal>


                <header className='login-header'>
                    <div className='exit' style={{ display: exitBtnVisible ? 'block' : 'none' }} onClick={this.exitFun}></div>
                </header>
                {/* <p className='loginTitle'>大脑360脑功能测评</p> */}
                <section className='login-content'>
                    <h2 style={{ fontSize: fontSizeVal }}>{loginName}</h2>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={this.onFinish}
                    >
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入用户名!',
                                }
                            ]}
                        >
                            <Input size="large" prefix={<UserOutlined className="site-form-item-icon" />} placeholder="用户名" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入密码',
                                },
                            ]}
                            style={{ marginBottom: '10px' }}
                        >
                            <Input size="large"
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="密码"
                            />
                        </Form.Item>

                        {/* <Form.Item
                            name="vcode"
                            rules={[
                                {
                                    required: true,
                                    message: '请输入验证码',
                                },
                            ]}
                        >
                            <Input size="large"
                                prefix={<KeyOutlined className="site-form-item-icon" />}
                                placeholder="验证码"
                            />
                        </Form.Item> */}
                        <div style={{ overflow: 'hidden' }}>
                            <Button type="link" block style={{ width: '90px', float: 'right' }} onClick={this.forgetPwd}>忘记密码</Button>
                        </div>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                登  录
                            </Button>
                        </Form.Item>
                    </Form>
                </section>

                <p className='loginInfo' style={{ display: companyInfoDisplay }}>Copyright 2004-2024 {companyName} <span style={{ cursor: 'pointer' }} onClick={this.serverFun}>用户协议</span> <span style={{ cursor: 'pointer' }} onClick={this.privacyFun}>隐私政策</span></p>
            </div >
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeRole: (data) => { dispatch(changeRoleLogin(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);