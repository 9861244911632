import React, { Component } from 'react';
import { Button, Card, Table, Space, Pagination, message, Modal, Tooltip } from 'antd';
import { AimOutlined, EditOutlined, UnorderedListOutlined, DeleteOutlined, SnippetsOutlined } from '@ant-design/icons';
import { BATCH, SINGLE } from '../../config/constant';
import SearchCriteria from '../../components/SearchCriteria/SearchCriteria';
import axios from 'axios';
import baseConfig from '../../config/BaseConfig';
import { connect } from 'react-redux';
import { addBreadcrumber, replaceBreadcrumber } from '../../redux/actions/Breadcrumber';
import NationData from '../../config/Nation';
import EducationData from '../../config/Education';
import AssessorAddImport from './components/addImport';

/* 受测者管理 */
class AssessorManage extends Component {

    constructor(props) {
        super(props);

        // 组件状态
        this.state = {
            list: [],
            currentPage: 1,
            count: 1,
            batchselectedKeys: [],
            singleSelecteKey: 0,
            deleteModalVisible: false,
            deleteModalText: '确定要删除用户【张三】吗？',
            deleteType: SINGLE,
            resetPwdModalVisible: false,
            resetPwdSingleKey: 0,
            currnetRole: props.loginData.role,
            tableLoading: false
        };
    }

    // 搜索条件数值
    seacrhParam = {
        fname: '',
        num: '',
        uname: '',
        phone: '',
        gender: '0',
        share_status: '0'
    };

    // 列表项
    columns = [
        {
            title: '编号',
            dataIndex: 'num',
            align: 'center',
            width: 180,
        },
        {
            title: '登录账号',
            dataIndex: 'uname',
            align: 'center',
            width: 180,
        },
        {
            title: '姓名',
            dataIndex: 'fname',
            align: 'center',
            width: 180,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            align: 'center',
            width: 130,
            render: (gender) => { return Number(gender) === 1 ? '男' : Number(gender) === 2 ? '女' : '未知' },
        },
        {
            title: '民族',
            dataIndex: 'nation',
            align: 'center',
            width: 130,
            render: (nation) => { return nation === null ? '未知' : this.nationText(nation)[0].title },
        },
        {
            title: '生日',
            dataIndex: 'birthday',
            align: 'center',
            width: 180,
            render: (birthday) => { return birthday === null ? '' : birthday.substr(0, 10) },
        },
        {
            title: '文化程度',
            dataIndex: 'education',
            align: 'center',
            width: 200,
            render: (education) => { return education === null ? '' : this.educationText(education)[0].title },
        },
        {
            title: '来源',
            dataIndex: 'share_status',
            align: 'center',
            width: 130,
            render: (share_status) => { return share_status === null ? '' : Number(share_status) === 1 ? '本地' : Number(share_status) === 2 ? '共享' : '未知' },
        },
        {
            title: '操作',
            dataIndex: 'operator',
            align: 'center',
            width: 200,
            render: (text, record) => (
                // <Space size="middle">
                //     <Tooltip title="创建测评">
                //         <Button shape="circle" style={{ display: this.state.currnetRole === 8 ? 'none' : 'inline-block' }} onClick={() => { this.goCreateAssess(record) }}><AimOutlined /></Button>
                //     </Tooltip>
                //     <Tooltip title="编辑">
                //         <Button shape="circle" style={{ display: this.state.currnetRole === 8 ? 'none' : 'inline-block' }} onClick={() => { this.goDetail(record) }}><EditOutlined /></Button>
                //     </Tooltip>
                //     <Tooltip title="删除">
                //         <Button shape="circle" style={{ display: this.state.currnetRole === 8 ? 'none' : 'inline-block' }} onClick={() => { this.deleteUserData([record]) }}><DeleteOutlined /></Button>
                //     </Tooltip>
                //     <Tooltip title="查看详情">
                //         <Button shape="circle" style={{ display: this.state.currnetRole === 8 ? 'inline-block' : 'none' }} onClick={() => { this.goDetail(record) }}><UnorderedListOutlined /></Button>
                //     </Tooltip>
                //     <Tooltip title="报告">
                //         <Button shape="circle" onClick={() => { }}><SnippetsOutlined /></Button>
                //     </Tooltip>

                // </Space>

                [...this.operatorBtns(record)]
            )
        },
    ];

    operatorBtns = (record) => {
        let btns = [];
        if (this.state.currnetRole === 8) {
            btns.push(
                <Space size="middle" key={'operatorItem' + record.id}>
                    <Tooltip title="查看详情">
                        <Button shape="circle" onClick={() => { this.goDetail(record) }}><UnorderedListOutlined /></Button>
                    </Tooltip>
                    <Tooltip title="报告">
                        <Button shape="circle" onClick={() => { this.seachReports(record) }}><SnippetsOutlined /></Button>
                    </Tooltip>
                </Space>
            );
        }
        if (this.state.currnetRole === 16) {
            if (record.share_status === 2) {
                btns.push(
                    <Space size="middle" key={'operatorItem' + record.id}>
                        <Tooltip title="查看详情">
                            <Button shape="circle" onClick={() => { this.goDetail(record) }}><EditOutlined /></Button>
                        </Tooltip>
                        <Tooltip title="报告">
                            <Button shape="circle" onClick={() => { this.seachReports(record) }}><SnippetsOutlined /></Button>
                        </Tooltip>
                    </Space>
                );
            }
            else {
                btns.push(
                    <Space size="middle" key={'operatorItem' + record.id}>
                        <Tooltip title="编辑">
                            <Button shape="circle" onClick={() => { this.goDetail(record) }}><EditOutlined /></Button>
                        </Tooltip>
                        <Tooltip title="删除">
                            <Button shape="circle" onClick={() => { this.deleteUserData([record]) }}><DeleteOutlined /></Button>
                        </Tooltip>
                        <Tooltip title="报告">
                            <Button shape="circle" onClick={() => { this.seachReports(record) }}><SnippetsOutlined /></Button>
                        </Tooltip>
                    </Space>
                );
            }
            // btns.push(
            //     <Space size="middle" key={'operatorItem' + record.id}>
            //         <Tooltip title="编辑">
            //             <Button shape="circle" onClick={() => { this.goDetail(record) }}><EditOutlined /></Button>
            //         </Tooltip>
            //         <Tooltip title="删除">
            //             <Button shape="circle" onClick={() => { this.deleteUserData([record]) }}><DeleteOutlined /></Button>
            //         </Tooltip>
            //         <Tooltip title="报告">
            //             <Button shape="circle" onClick={() => { this.seachReports(record) }}><SnippetsOutlined /></Button>
            //         </Tooltip>
            //     </Space>
            // );
        }
        else if (this.state.currnetRole === 4) {
            if (record.share_status === 2) {
                btns.push(
                    <Space size="middle" key={'operatorItem' + record.id}>
                        <Tooltip title="查看详情">
                            <Button shape="circle" onClick={() => { this.goDetail(record) }}><EditOutlined /></Button>
                        </Tooltip>
                        <Tooltip title="报告">
                            <Button shape="circle" onClick={() => { this.seachReports(record) }}><SnippetsOutlined /></Button>
                        </Tooltip>
                    </Space>
                );
            }
            else {
                btns.push(
                    <Space size="middle" key={'operatorItem' + record.id}>
                        <Tooltip title="创建测评">
                            <Button shape="circle" onClick={() => { this.goCreateAssess(record) }}><AimOutlined /></Button>
                        </Tooltip>
                        <Tooltip title="编辑">
                            <Button shape="circle" onClick={() => { this.goDetail(record) }}><EditOutlined /></Button>
                        </Tooltip>
                        <Tooltip title="删除">
                            <Button shape="circle" onClick={() => { this.deleteUserData([record]) }}><DeleteOutlined /></Button>
                        </Tooltip>
                        <Tooltip title="报告">
                            <Button shape="circle" onClick={() => { this.seachReports(record) }}><SnippetsOutlined /></Button>
                        </Tooltip>
                    </Space>
                );
            }
            // btns.push(
            //     <Space size="middle" key={'operatorItem' + record.id}>
            //         <Tooltip title="创建测评">
            //             <Button shape="circle" onClick={() => { this.goCreateAssess(record) }}><AimOutlined /></Button>
            //         </Tooltip>
            //         <Tooltip title="编辑">
            //             <Button shape="circle" onClick={() => { this.goDetail(record) }}><EditOutlined /></Button>
            //         </Tooltip>
            //         <Tooltip title="删除">
            //             <Button shape="circle" onClick={() => { this.deleteUserData([record]) }}><DeleteOutlined /></Button>
            //         </Tooltip>
            //         <Tooltip title="报告">
            //             <Button shape="circle" onClick={() => { this.seachReports(record) }}><SnippetsOutlined /></Button>
            //         </Tooltip>
            //     </Space>
            // );
        }

        return btns;
    }

    // 搜索条件组件初始数据
    seacrhItemsData = [
        { title: '姓名', inputType: 'input', seacrhType: 'fname' },
        { title: '编号', inputType: 'input', seacrhType: 'num' },
        { title: '登录名', inputType: 'input', seacrhType: 'uname' },
        { title: '联系电话', inputType: 'input', seacrhType: 'phone' },
        { title: '性别', inputType: 'select', seacrhType: 'gender', children: [{ title: '全部', value: '0' }, { title: '男', value: '1' }, { title: '女', value: '2' }] },
        { title: '来源', inputType: 'select', seacrhType: 'share_status', children: [{ title: '全部', value: '0' }, { title: '本地', value: '1' }, { title: '共享', value: '2' }] },
        // { title: '', inputType: 'buttons', seacrhType: '', children: [{ title: '搜索', key: 'submit' }, { title: '新增', key: 'add' }, { title: '删除', key: 'delete' }, { title: '导出', key: 'export' }] },
        { title: '', inputType: 'buttons', seacrhType: '', children: [{ title: '新增', key: 'add' }, { title: '批量导入', key: 'addImport' }, { title: '下载创建模板', key: 'downloadfile' }, { title: '删除', key: 'delete' }, { title: '导出', key: 'export' }] },
    ];

    // 组件挂载
    componentDidMount() {
        this.seacrhDataFun({ page: 1 });
    }

    // 文化程度显示
    educationText = (educationValue) => {
        return EducationData.filter((tempObj) => {
            return tempObj.value === educationValue + '';
        });
    }

    // 民族显示
    nationText = (nationValue) => {
        return NationData.filter((tempObj) => {
            return tempObj.value === nationValue + '';
        });
    }

    // 获取列表数据
    seacrhDataFun = (data = null) => {
        let tempParam = {};
        Object.assign(tempParam, this.seacrhParam);
        tempParam.page = this.state.currentPage;

        if (data) {
            tempParam.page = data.page;
        }
        this.setState({ tableLoading: true });
        axios.get(baseConfig.httpUrl + `/api/user/get-tester-list`, { params: tempParam }).then(
            response => {
                if (response.data.res === 1) {
                    const { data, page, count } = response.data;

                    data.map((dataObj) => {
                        return dataObj.key = dataObj.id;
                    })

                    this.setState({
                        list: [...data],
                        currentPage: page * 1,
                        count: count,
                        batchselectedKeys: [],
                        tableLoading: false
                    });
                }
                else {
                    this.setState({
                        list: [],
                        currentPage: 1,
                        count: 1,
                        batchselectedKeys: [],
                        tableLoading: false
                    });
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            },
            error => { }
        );
    }

    // 每条数据详情
    goDetail = (data) => {
        this.props.add({ path: `/assessordetail`, name: '受测者详情' });
        this.props.history.push(
            baseConfig.routeUrl + `/assessordetail`,
            {
                id: data.id,
                num: data.num,
                fname: data.fname,
                gender: data.gender,
                birthday: data.birthday,
                nation: data.nation,
                education: data.education,
                handedness: data.handedness,
                blood: data.blood,
                height: data.height,
                weight: data.weight,
                blood_pressure: data.blood_pressure,
                blood_sugar: data.blood_sugar,
                eyesight_left: data.eyesight_left,
                eyesight_right: data.eyesight_right,
                phone: data.phone,
                share_status: data.share_status,
                address: data.address,
                disease_history: data.disease_history,
                medicine_history: data.medicine_history,
                currnetRole: this.state.currnetRole,
                labels: data.labels
            }
        );
    }

    // 创建测评
    goCreateAssess = (data) => {
        this.props.add({ path: `/createassess`, name: '创建测评' });
        this.props.history.replace(
            baseConfig.routeUrl + `/createassess`,
            {
                id: data.user_id,
            }
        );
    }

    // 跟新搜索条件数值
    updateSeacrhItems = (seacrhData) => {
        let tempObj = this.formatParams(seacrhData);
        Object.assign(this.seacrhParam, tempObj);
    }

    // 导出
    exportDataFun = () => {

        let exportUrl = baseConfig.httpUrl + '/api/user/get-tester-list?';
        for (let key in this.seacrhParam) {
            if (this.seacrhParam[key] !== undefined) {
                exportUrl += key + '=' + this.seacrhParam[key] + '&';
            }
        }
        exportUrl += 'export=1';
        window.open(exportUrl, '_blank');
    }

    // 删除用户
    deleteUserData = (data) => {
        const { batchselectedKeys } = this.state;
        // console.log('deleteUserData-data', data)
        // console.log('deleteUserData-batchselectedKeys', batchselectedKeys)

        let tempdeleteModalText = '';
        if (data) {
            tempdeleteModalText = `确定要删除用户 【${data[0].fname}】 吗？`;
            this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: SINGLE, singleSelecteKey: data[0].id });
        }
        else {
            if (batchselectedKeys.length < 1) {
                message.warning({
                    content: '请选择要删除的用户',
                    duration: 3
                });
            }
            else {
                tempdeleteModalText = `确定要删除用户选中吗？`;
                this.setState({ deleteModalVisible: true, deleteModalText: tempdeleteModalText, deleteType: BATCH });
            }
        }
    }

    // 点击确定-删除
    deleteOk = () => {
        let tempParam = this.state.deleteType === SINGLE ? [this.state.singleSelecteKey] : this.state.batchselectedKeys;
        axios.post(baseConfig.httpUrl + `/api/user/delete-user`, { ids: tempParam }).then(
            response => {
                this.setState({ deleteModalVisible: false });
                if (response.data.res === 1) {

                    message.success({
                        content: '成功',
                        className: 'custom-class',
                    });

                    // 重新获取列表数据
                    this.seacrhDataFun();
                }
                else {
                    message.error({
                        content: response.data.msg,
                        className: 'custom-class',
                    });
                }
            }
        );
    }

    // 关闭弹框-删除
    deleteCancel = () => {
        this.setState({ deleteModalVisible: false });
    }

    // 用户报告
    seachReports = (data) => {
        this.props.replace([{ path: '/assessmanage', name: '测评管理', grade: 1 }, { path: '/reportmanage', name: '报告管理', grade: 2 }]);
        this.props.history.replace(
            baseConfig.routeUrl + `/reportmanage`,
            {
                name: data.fname,
            }
        );
    }

    // 列表 每行数据选择checkbox
    onSelectChange = batchselectedKeys => {
        this.setState({ batchselectedKeys });
    };

    // 新增用户
    addUser = () => {
        this.props.add({ path: `/assessordetail`, name: '受测者详情' });
        this.props.history.push(baseConfig.routeUrl + `/assessordetail`, {
            id: 0,
            num: '',
            fname: '',
            gender: '1',
            birthday: undefined,
            nation: '1',
            education: '1',
            handedness: '1',
            blood: '',
            height: '',
            weight: '',
            blood_pressure: '0',
            blood_sugar: '0',
            eyesight_left: '',
            eyesight_right: '',
            phone: '',
            address: '',
            disease_history: '',
            medicine_history: '',
        });
    }

    addImportFun = () => {
        this.setState({ addImportVisible: true });
    }

    /**
     * 新增导入-确认
     * @param {*} data 
     */
    addImportConfirm = (data) => {
        axios.post(baseConfig.httpUrl + `/api/user/import-tester`, data).then(
            response => {
                this.setState({ addImportVisible: false });
                if (response.data.res === 1) {
                    message.success(response.data.msg);
                    // 重新获取列表数据
                    this.seacrhDataFun();
                }
                else {
                    message.error(response.data.msg);
                }
            }
        );
    }

    /**
     * 新增学员导入 - 取消
     */
    addImportCancel = () => {
        this.setState({ addImportVisible: false });
    }

    /**
     * 下载创建模板
     */
    downloadFileFun = () => {
        window.open('https://www.mind360.com.cn/penguin/public/批量上传学生模板.xlsx', '_blank');
    }

    // 格式化参数
    formatParams = (seacrhParam) => {
        for (let key in seacrhParam) {
            seacrhParam[key] = seacrhParam[key] === undefined ? '' : seacrhParam[key];
        }
        return seacrhParam;
    }

    // 换页
    changePage = (pageNum, pageSize) => {
        this.seacrhDataFun({ page: pageNum });
    }

    // 搜索条件过滤
    remakeSeacrhData = () => {
        if (this.state.currnetRole && this.state.currnetRole === 8) {
            let buttons = this.seacrhItemsData[6].children;
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].key === 'add') {
                    buttons.splice(i, 1);
                }
            }
            for (let i = 0; i < buttons.length; i++) {
                if (buttons[i].key === 'delete') {
                    buttons.splice(i, 1);
                }
            }
        }
    }

    render() {
        this.remakeSeacrhData();

        const { batchselectedKeys, tableLoading } = this.state;
        const rowSelection = {
            batchselectedKeys,
            onChange: this.onSelectChange,
        };

        return (
            <Card title="" style={{ minHeight: '100%' }}>

                {/* 新增学员-导入 */}
                {
                    this.state.addImportVisible && <AssessorAddImport
                        visible={this.state.addImportVisible}
                        confirmFun={this.addImportConfirm}
                        cancelFun={this.addImportCancel}
                    />
                }

                {/* 删除用户 */}
                <Modal
                    className='modals'
                    title="删除用户"
                    visible={this.state.deleteModalVisible}
                    onOk={this.deleteOk}
                    onCancel={this.deleteCancel}
                    maskClosable={false}
                >
                    <p className='modalText'>{this.state.deleteModalText}</p>
                </Modal>

                {/* 搜索条件 */}
                <div>
                    <SearchCriteria
                        seacrhItems={this.seacrhItemsData}
                        addFun={this.addUser}
                        addImportFun={this.addImportFun}
                        deleteFun={this.deleteUserData}
                        exportFun={this.exportDataFun}
                        seacrhFun={this.seacrhDataFun}
                        downloadFileFun={this.downloadFileFun}
                        updateSeacrhValue={this.updateSeacrhItems}
                        initialValueParam={{ gender: '0', share_status: '0' }}
                    />
                </div>

                {/* 列表 */}
                <Table
                    dataSource={this.state.list}
                    pagination={false}
                    columns={this.columns}
                    rowSelection={rowSelection}
                    loading={tableLoading}
                >
                </Table>

                {/* 分页 */}
                <Pagination
                    onChange={this.changePage}
                    style={{ marginTop: 30 }}
                    defaultCurrent={1}
                    current={this.state.currentPage}
                    total={this.state.count}
                    hideOnSinglePage={true}
                    showQuickJumper={{
                        goButton: <span><span>&nbsp;&nbsp;&nbsp;&nbsp;</span> <Button type='primary'>Go</Button></span>,
                    }}
                    showTotal={(total, range) => `当前显示${range[0]}-${range[1]}条  共${total}条记录 `}
                    showSizeChanger={false}
                />
            </Card >
        )
    }
}

// 容器组件
const mapStateToProps = (state) => {
    return { breadcrumberData: state.breadcrumber, loginData: state.login }
}

const mapDispatchToProps = (dispatch) => {
    return {
        add: (data) => { dispatch(addBreadcrumber(data)) },
        replace: (data) => { dispatch(replaceBreadcrumber(data)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssessorManage)