import React, { Component } from 'react'
import { connect } from 'react-redux'
import './index.less'
import { CompanyDataList, DomainData } from '../../config/Common';

/**
 * 
 */
class Service extends Component {

  state = {}

  componentDidMount() {

  }

  render() {
    let loginName = '脑云梯脑智综合评估系统', companyName = '';
    let domainUrl = window.location.host;
    for (let key in DomainData) {
      if (domainUrl == key) {
        loginName = DomainData[key];
      }
    }

    CompanyDataList.forEach((item) => {
      if (domainUrl == item['host']) {
        companyName = item['companyName'];
      }
    })

    return (
      <div className='service'>
        <div className='service_header'>{loginName}</div>
        <div className='service_content'>

          <div className='service_titleTotle'>用户协议</div>
          本网站由{companyName}独立开发、运营。
          本公司在此特别提醒用户认真阅读、充分理解本协议各条款，特别是其中所涉及的免除、限制本公司责任的条款、对用户权利限制条款、争议解决和法律适用等。请用户审慎阅读并选择接受或不接受本协议（未成年人应在监护人陪同下阅读）。除非用户接受本协议所有条款，否则用户无权使用本公司于本协议下所提供的服务。用户在本网站注册、登录或使用本网站服务，即表示用户完全接受本协议的全部条款，并同意接受其约束。<br />
          <span className='service_title'>1.接受</span><br />
          1.1 当用户选择访问或使用本网站有关服务，将视为同意接受本协议全部条款的约束。<br />
          1.2 除非另有明确规定，本网站所推出的新产品、新功能和新服务，均无条件的适用本协议。本网站保留在任何时候修改本协议条款的权利，且无需另行通知。用户在使用服务时应关注并遵守。<br />
          <span className='service_title'>2.用户</span><br />
          2.1 本网站用户定义为符合下列条件的组织或个人：<br />
          ① 在本网站注册过账号；<br />
          ② 具备享受本网站服务的完全民事行为能力；<br />
          ③ 遵守相关法律规定，承诺对相关行为承担相应的法律责任。<br />
          2.2 不具备上述资格的注册用户，本公司一经发现，有权立即注销该用户账号，并有权禁止被注销用户资格的自然人、法人及其他组织再次使用网站。<br />
          <span className='service_title'>3.注册条款</span><br />
          3.1 用户可以通过在本网站注册账号使用本网站提供的各项服务，注册时须遵守：<br />
          ① 因注册信息不真实而引起的问题由用户本人承担，本网站不负任何责任，并有权暂停或终止用户的账号。<br />
          ② 用户需自行妥善保管账户和密码，不得将自身账号转让、转借给其他第三人使用，对该账号及其密码负保管义务，并对由此引起的纠纷、损失和法律责任担责。<br />
          ③ 如用户代表其他自然人、法人或其他组织在网站上注册会员，则需声明并保证已获得授权并有权使前述法律主体受本协议的约束。<br />
          3.2 本网站对用户的电子邮件、手机号等隐私资料进行保护，承诺不会在未获得用户许可的情况下擅自将用户的个人资料信息出 租或出售给任何第三方。以下情况除外：<br />
          ① 用户同意让第三方共享资料。<br />
          ② 用户同意公开其个人资料，享受为其提供的产品和服务。<br />
          ③ 本网站需要听从法庭传票、法律命令或遵循法律程序。<br />
          ④ 本网站发现用户违反了用户协议或本站其它使用规定。<br />
          <span className='service_title'>4.使用条款</span><br />
          4.1 使用本网站时，用户须遵守国家的法律法规和规章制度，遵守网站有关协议、规定、规则、程序和惯例，不得利用网站从事任何非法或不正当活动。 <br />
          4.2 用户不得将任何内部资料、机密资料、涉及他人隐私资料或侵犯任何人的专利、商标、著作权、商业秘密或其他专属权利之内容加以上传、张贴、或以其他方式传送。<br />
          4.3 用户有义务在注册及享受服务时提供真实、详尽、准确的资料/信息（包括但不限于电子邮件地址、联系电话、联系地址、邮政编码等），并保证资料/信息的及时、有效更新，使得网站在必要时可通过上述联系方式与用户本人进行联系。<br />
          4.4 网站的通知将以电子邮件形式发送至用户的电子邮箱（您同意，如您修改原注册时提交的电子邮箱地址、并通过网站审核的，以新电子邮箱地址为准）。该电子邮件进入前述电子邮箱接收系统时，视为送达。<br />
          4.5 网站有权审核、发布或删除会员提交的信息。网站将尽力核查会员提交的信息，但并不能完全保证信息的真实性、准确性和合法性，同时也不承担由此引至的任何法律责任。<br />
          4.6用户同意，本网站有权对网站服务、政策和/或本协议作出变更；任何修改或补充在其被张贴在网站时立即生效，对双方都有约束力。重大变更将在网站首页页面提示。用户继续使用网站、享受服务的，即视为对该等变更之确认。<br />
          <span className='service_title'>5.中止与终止</span><br />
          对于用户的以下行为，网站有权在未告知的情况下中止或终止其相关服务或者拒绝其以任何形式使用网站，并保留追究法律责任的权利：<br />
          5.1 如发生下列任何一种情形，网站有权在未通知用户的情况下停止提供服务：<br />
          ① 用户违反本协议规定。<br />
          ② 按照行政机关和/或授权职能部门的要求。<br />
          ③ 其他本网站认为需终止服务的情况。<br />
          ④ 计划破坏、攻击网站网站电脑系统、网络的完整性，或者试图擅自进入网站电脑系统和网络。使用或提供含有毁坏、干扰、截取或侵占任何系统、数据或个人资料的任何电脑病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他破坏性程序。盗用他人在网站上的登录名和/或密码；未经网站同意的账号出售或授权使用行为；通过或从事涉及网站的欺诈活动；通过或从事涉及网站的知识产权侵权行为。<br />
          ⑤ 违反国家法律法规、规章制度、网站有关协议、规定、规则、程序和惯例的其他行为。<br />
          ⑥ 本协议终止或更新时，用户未确认新的协议的。<br />
          ⑦ 其它本公司认为需终止服务的情况。<br />
          <span className='service_title'>6.责任声明</span><br />
          6.1 鉴于网络服务的特殊性，网站不对除基本安全、稳定以外其的他外部干扰、黑客攻击等因素造成的用户与网站服务器之间的连接受阻而无法访问负责。用户同意自己承担因网站意外中断、操作或传输的迟延、电脑病毒、网络连接故障、未经授权的进入等引起的损失。<br />
          6.2 因不可抗力或者其他意外事件，使得本协议的履行不可能、不必要或者无意义的，双方均不承担责任。本合同所称之不可抗力意指不能预见、不能避免并不能克服的客观情况，包括但不限于战争、台风、水灾、火灾、雷击或地震、罢工、暴动、法定疾病、黑客攻击、网络病毒、电信部门技术管制、政府行为或任何其它自然或人为造成的灾难等客观情况。<br />
          6.3 用户明确同意其使用本网站服务所存在的风险及其后果将完全由其自己承担，网站对用户不承担任何责任。如因用户违反有关法律、法规或本协议项下的任何条款而给网站或任何其他第三人造成损失，用户同意承担由此造成的损害赔偿责任。<br />
          <span className='service_title'>7.知识产权</span><br />
          7.1 脑云梯及公司其他相关产品的商标、服务标记、标识、域名和其他显著品牌特征等均受著作权、商标及其它法律保护，未经相关权利人同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。<br />
          <span className='service_title'>8.法律适用及管辖</span><br />
          8.1 本协议的订立、变更、执行和解释及争议的解决，均应适用中华人民共和国法律。如与本协议有关的某一特定事项缺乏明确法律规定，则应参照通用的国际商业惯例和行业惯例。<br />
          8.2 因本协议内容或其执行发生争议，双方应友好协商解决；协商不成时，任何一方均可向公司经营所在地的人民法院提起诉讼。<br />
          <span className='service_title'>9.其他</span><br />
          9.1 用户使用网站相关服务时，亦应知晓、认可并同意遵守相关服务的额外条款及条件。<br />
          9.2 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。<br />
          9.3 本协议之条款是可分割的，任何协议条款被认定为无效或不可执行时，不影响其他条款之效力。<br />
        </div>
      </div >
    )
  }
}

// 容器组件
const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Service);