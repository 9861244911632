

const MenuDataFun = (data) => {

    const MenuData = [
        {
            title: '数据看板',
            key: '/databoard',
            icon: 'none',
        },
        {
            title: '机构管理',
            key: '/organmanage',
            icon: 'appstore',
            children: [
                {
                    title: '用户管理',
                    key: '/usermanage',
                    icon: 'none',
                    father: "/organmanage",
                    father_name: "机构管理"
                },
                {
                    title: '机构设置',
                    key: '/organsetting',
                    icon: 'none',
                    father: "/organmanage",
                    father_name: "机构管理"
                },
            ]
        },
        {
            title: '测评管理',
            key: '/assessmanage',
            icon: 'appstore',
            children: [
                {
                    title: '受测者管理',
                    key: '/assessormanage',
                    icon: 'none',
                    father: "/assessmanage",
                    father_name: "测评管理"
                },
                {
                    title: '共享管理',
                    key: '/sharemanage',
                    icon: 'none',
                    father: "/assessmanage",
                    father_name: "测评管理"
                },
                {
                    title: '报告管理',
                    key: '/reportmanage',
                    icon: 'none',
                    father: "/assessmanage",
                    father_name: "测评管理"
                },
            ]
        },
        {
            title: '用户中心',
            key: '/usercenter',
            icon: 'appstore',
            children: [
                {
                    title: '个人资料',
                    key: '/personaldata',
                    icon: 'none',
                    father: "/usercenter",
                    father_name: "用户中心"
                },
                {
                    title: '安全设置',
                    key: '/safetysetting',
                    icon: 'none',
                    father: "/usercenter",
                    father_name: "用户中心"
                },
                {
                    title: '账户充值',
                    key: '/accountrecharge',
                    icon: 'none',
                    father: "/usercenter",
                    father_name: "用户中心"
                },
                {
                    title: '充值记录',
                    key: '/rechargerecord',
                    icon: 'none',
                    father: "/usercenter",
                    father_name: "用户中心"
                },
                {
                    title: '消费记录',
                    key: '/consumerecord',
                    icon: 'none',
                    father: "/usercenter",
                    father_name: "用户中心"
                },
            ]
        },
        {
            title: '任务管理',
            key: '/mydownload',
            icon: 'appstore',
        },
    ];


    if (data.display_kanban == 0) {
        MenuData.splice(0, 1);
    }

    // 操作员：4 管理员：8 助理：16
    if (data.role === 4 || data.role === 16) {
        if (data.display_kanban == 1) {
            MenuData.splice(1, 1);
        }
        else {
            MenuData.splice(0, 1);
        }
    }
    else if (data.role === 8) {
        if (data.display_kanban == 1) {
            MenuData[2].children.splice(1, 1);
        }
        else {
            MenuData[1].children.splice(1, 1);
        }
    }
    return MenuData;
}

export default MenuDataFun;