import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Login from './pages/Login/Login';
import Admin from './pages/Admin/Admin';
import ForgetPwd from './pages/ForgetPwd/ForgetPwd';
import baseConfig from './config/BaseConfig';
import Privacy from './pages/Agreement/Privacy';
import Service from './pages/Agreement/Service';
import agreement from './pages/Agreement/Agreement';

export default class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={baseConfig.routeUrl + '/login'} component={Login}></Route>
                    <Route path={baseConfig.routeUrl + '/forgetpwd'} component={ForgetPwd}></Route>
                    <Route path={baseConfig.routeUrl + '/service'} component={Service}></Route>
                    <Route path={baseConfig.routeUrl + '/privacy'} component={Privacy}></Route>
                    <Route path={baseConfig.routeUrl + '/agreement'} component={agreement}></Route>
                    <Route path={baseConfig.routeUrl + '/'} component={Admin}></Route>
                    <Redirect to={baseConfig.routeUrl + '/login'} />
                </Switch>
            </BrowserRouter>
        )
    }
}
